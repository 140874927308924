import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/layout";
import './App.css';
import Index from "./components/pages/Index";
import About from "./components/pages/About";
import Sala from "./components/pages/Sala";
import Kitchen from "./components/pages/Kitchen";
import Rooms from "./components/pages/Rooms";
import Gallery from "./components/pages/Gallery";
import Price from "./components/pages/Price";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Index />}></Route>
                    <Route path="/Kontakt" element={<About />}></Route>
                    <Route path="/Cennik" element={<Price />}></Route>
                    <Route path="/Galeria" element={<Gallery />}></Route>
                    <Route path="/Sala" element={<Sala />}></Route>
                    <Route path="/Kuchnia" element={<Kitchen />}></Route>
                    <Route path="/Pokoje" element={<Rooms />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
