import "./Footer.min.css"

function Footer() {


    return (
        <div className="row w-100 footer align-items-center mb-1" style={{ marginTop: '-10%' }}>

            <div className="col logo flex-grow-0">
                <img src="./logo.svg" alt="Domek Salsa Logo" />
            </div>

            <div className="col contact flex-grow-1 h-auto px-5">
                Kontakt:<br /><br />
                Domek Salsa<br />
                ul. Floriana Ceynowy 8,<br />
                84-120 Władyslawowo<br />
                Tel: +48 784 909 239<br />
                modzelewska21@gmail.com
            </div>

        </div>);
}

export default Footer;