import Footer from "../footer/Footer";

function Gallery() {
    const handleImageClick = (event) => {
        const imageUrl = event.target.getAttribute("src");
        if (imageUrl) {
            window.open(imageUrl.replace('thumb/', ''), "_blank");
        }
    };
    return (
        <>

            <div className="parallax">
                <section className="image" id="img3">
                    <section className="heading">
                        Galeria zdjęć
                    </section>
                </section>

                <section className="w-100 text align-items-center">
                    <div className="container gallery">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 mt-lg-5">
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj15.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj16.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj17.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj18.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj11.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj12.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj14.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj19.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj20.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />



                                <img
                                    src="./images/lazienka/thumb/domek_salsa_wladyslawowo_lazienka1.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/lazienka/thumb/domek_salsa_wladyslawowo_lazienka2.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />

                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0">



                             
                               

                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj1.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj2.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj3.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj5.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj6.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj13.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />



                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia4.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia5.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia6.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia7.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia8.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />




                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0 mt-lg-5">
                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria10.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria9.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj7.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj8.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj9.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria2.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria6.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria8.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria11.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Yosemite National Park"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria7.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria4.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Wintry Mountain Landscape"
                                    onClick={handleImageClick}
                                />

                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria3.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/galeria/thumb/domek_salsa_wladyslawowo_galeria5.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Yosemite National Park"
                                    onClick={handleImageClick}
                                />




                            </div>
                        </div>
                    </div>


                </section>
                <section className="w-100 text align-items-center">
                    <Footer></Footer>
                </section>
            </div>

        </>
    );
}

export default Gallery;