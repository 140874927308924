import Footer from "../footer/Footer";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MagnifyingGlass } from 'react-loader-spinner';

function Price() {
    const [cennikValue, setCennikValue] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://domeksalsaapi.azurewebsites.net/api/Function1');
                const data = response.data;
                const { Cennik } = data; // Extracting the Cennik value from the JSON

                setCennikValue(Cennik);
                setIsLoading(false); // Set isLoading to false when the data is loaded
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const generateRawHTML = () => {
        // Generate your raw HTML content using the cennikValue variable
        // For example:
        return `${cennikValue}`;
    };

    return (
        <>
            <div className="parallax">
                <section className="image" id="img3">
                    <section className="heading">
                        Ceny i pakiety
                    </section>
                </section>

                <section className="w-100 text flex-column align-items-center">
                    <div className="col-sm-12 col-lg-10 col-xxl-6" style={{ textAlign: 'justify' }} >
                        <h3>CENNIK</h3>

                        {isLoading ? (
                            <div className="spinner-container">
                                <MagnifyingGlass
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="MagnifyingGlass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="MagnifyingGlass-wrapper"
                                    glassColor='#c0efff'
                                    color='#2a5d6b'
                                />
                            </div>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: generateRawHTML() }} />
                        )}
                    </div>
                </section>

                <section className="w-100 text align-items-center">
                    <Footer></Footer>
                </section>
            </div>
        </>
    );
}

export default Price;