import Footer from "../footer/Footer";
import "./Sala.min.css";

function Sala() {
    const handleImageClick = (event) => {
        const imageUrl = event.target.getAttribute("src");
        if (imageUrl) {
            window.open(imageUrl.replace('thumb/', ''), "_blank");
        }
    };
    return (
        <>

            <div className="parallax">
                <section className="image" id="img3">
                    <section className="heading" style={{ backdropFilter: 'brightness(0.5);' }}>
                        Zajrzyj do sali
                    </section>
                </section>

                <section className="w-100 text flex-column align-items-center">
                    <div className="col-sm-12 col-lg-10 col-xxl-6" style={{ textAlign: 'justify' }} >
                        <h3>WSPÓLNA PRZESTRZEŃ I ZABAWA</h3>


                        <p className="regular-text">
                            Przestrzenna sala o wielkości 47m2 wraz z jadalnią dostosowana jest dla 14 osobowej grupy. Jedna ze ścian pokryta jest lustrami.
                        </p>
                    </div>
                </section>
                <section className="w-100 text align-items-center">
                    <div className="container gallery">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 mt-lg-5">




                                <img
                                    src="./images/sala/thumb/domek_salsa_wladyslawowo_sala7.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />

                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0 indent" >

                                <img
                                    src="./images/sala/thumb/domek_salsa_wladyslawowo_sala6.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Yosemite National Park"
                                    onClick={handleImageClick}

                                />

                                <img
                                    src="./images/sala/thumb/domek_salsa_wladyslawowo_sala1.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4 "
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                                

                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0 mt-lg-5">
                                <img
                                    src="./images/sala/thumb/domek_salsa_wladyslawowo_sala4.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    onClick={handleImageClick}
                                />
                               

                            </div>
                        </div>
                    </div>


                </section>
                <section className="w-100 text align-items-center">
                    <Footer></Footer>
                </section>
            </div>

        </>
    );
}

export default Sala;