import Footer from "../footer/Footer";
import "./Rooms.min.css";

function Rooms() {
    const handleImageClick = (event) => {
        const imageUrl = event.target.getAttribute("src");
        if (imageUrl) {
            window.open(imageUrl.replace('thumb/', '').replace('.mp4','.MOV'), "_blank");
        }
    };
    return (
        <>

            <div className="parallax">
                <section className="image" id="img3">
                    <section className="heading">
                        Poznaj nasze pokoje
                    </section>
                </section>

                <section className="w-100 text flex-column align-items-center">
                    <div className="col-sm-12 col-lg-10 col-xxl-6" style={{ textAlign: 'left' }} >
                        <h3>KOMFORTOWE I WYGODNE</h3>


                        <p className="regular-text">
                            Minimalizm, subtelna elegancja, ręcznie wykonane dekoracje znajdujące się w pokojach podkreślają piękno otoczenia i pozwalają w komfortowych warunkach napawać się jego urokiem. Nowoczesne wnętrza zaskakują przytulnością, a ich aranżacja sprzyja atmosferze wypoczynku.
                        </p>
                        <p className="regular-text">Nasz obiekt składa sie z sześciu pokoi, sali, kuchni oraz przestronnego ogrodu.
                            Wszystkie przestrzenie zostały zaprojektowane przez utalentowanych polskich artystów z dużym naciskiem na detale.
                            Domek Salsa charakteryzuje się licznymi udogodnieniami. Mimo zbliżonej stylistyki, każda przestrzeń ma inny charakter.</p>
                            <br/>
                        <h3>POKÓJ 1</h3>
                        <div className="row" >
                            <div className="col">
                                <p>Wyjątkowy pokój mieści się na parterze. Gości zachwyca połączenie elementów morza z nowoczesnym designem. Jest wyposażony w łóżko 2 osobowe.</p>
                            </div>
                            <div className="col d-flex justify-content-center align-items-start">
                                <video className="img-item shadow-1-strong rounded" autoPlay muted loop playsInline style={{ maxWidth: '100%', height: 'auto' }}>
                                    <source src="./images/pokoj/thumb/pokoj1.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                        </div>
                        <br />
                        <h3 className="left justify-content-end">POKÓJ 2</h3>

                        <div className="row" >
                            <div className="col d-flex justify-content-center align-items-start">
                                <video className="img-item shadow-1-strong rounded" autoPlay muted loop playsInline style={{ maxWidth: '100%', height: 'auto' }}>
                                    <source src="./images/pokoj/thumb/pokoj2.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="col">
                                <p >Ten klimatyczny pokój wyposażony jest w jedno łóżko dwuosobowe i jedno łóżko pojedyncze. Gości rozpieszcza przestrzennym i słonecznym balkonem. Rafia, ratan, wiklina dają niesamowite doznania wizualne, sprawiając, iż relaks i odpoczynek w tym pokoju nabiera nowego wymiaru.</p>
                            </div>

                        </div>
                        <br />
                        <h3>POKÓJ 3</h3>
                        <div className="row" >
                            <div className="col">
                                <p>Wyjątkowy pokój bogaty w ręcznie wykonane ozdoby ścienne oraz lampy. Posiada łóżko 2 osobowe.</p>
                            </div>
                            <div className="col d-flex justify-content-center align-items-start">
                                <video className="img-item shadow-1-strong rounded" autoPlay muted loop playsInline style={{ maxWidth: '100%', height: 'auto' }}>
                                    <source src="./images/pokoj/thumb/pokoj3.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                        </div>
                        <br />
                        <h3 className="left justify-content-end">POKÓJ 4</h3>
                        <div className="row" >
                            <div className="col d-flex justify-content-center align-items-start">
                                <video className="img-item shadow-1-strong rounded" autoPlay muted loop playsInline style={{ maxWidth: '100%', height: 'auto' }}>
                                <source src="./images/pokoj/thumb/pokoj4.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            </div>
                            <div className="col">
                                <p className="text-end">Gościom szukającym przede wszystkim odpoczynku i relaksu, proponujemy ten pokój, który zdobiony jest widokiem na zatokę. Posiada dwa łóżka pojedyncze.</p>
                            </div>
                            
                        </div>
                        <br />
                        <h3>POKÓJ 5</h3>
                        <div className="row" >
                            <div className="col">
                                <p className="text-end">Jeden z ładniejszych i przestronnych pokoi. Prace ręczne zdobiące przestrzenie tego pokoju, słoneczny i przestronny balkon są atutami bardzo cenionymi przez Naszych gości. Wyposażony jest w jedno łóżko dwuosobowe i jedno łóżko pojedyncze.</p>
                            </div>
                            <div className="col d-flex justify-content-center align-items-start">
                                <video className="img-item shadow-1-strong rounded" autoPlay muted loop playsInline style={{ maxWidth: '100%', height: 'auto' }}>
                                    <source src="./images/pokoj/thumb/pokoj5.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                        </div>
                        <br />
                        <h3 className="left justify-content-end ">POKÓJ 6</h3>
                        <div className="row" >
                            <div className="col d-flex justify-content-center align-items-start">
                                <video className="img-item shadow-1-strong rounded" autoPlay muted loop playsInline style={{ maxWidth: '100%', height: 'auto' }}>
                                    <source src="./images/pokoj/thumb/pokoj6.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="col">
                                <p>Najmniejszy, klimatyczny, słoneczny pokój z balkonem, który zachęca do relaksu każdego Gościa Domku Salsa. Wyposażony jest w łóżko dwuosobowe.</p>
                            </div>

                        </div>

                    </div>
                </section>

                <section className="w-100 text align-items-center">
                    <Footer></Footer>
                </section>
            </div>

        </>
    );
}

export default Rooms;