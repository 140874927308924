import Footer from "../footer/Footer";
import "./Kitchen.min.css";
function Kitchen() {

    const handleImageClick = (event) => {
        const imageUrl = event.target.getAttribute("src");
        if (imageUrl) {
            window.open(imageUrl.replace('thumb/', ''), "_blank");
        }
    };

    return (
        <>

            <div className="parallax">
                <section className="image" id="img3">
                    <section className="heading">
                        Zajrzyj do Kuchni
                    </section>
                </section>

                <section className="w-100 text flex-column align-items-center">
                    <div className="col-sm-12 col-lg-10 col-xxl-6" style={{ textAlign: 'justify' }} >
                        <h3>KOMPLEKSOWO WYPOSAŻONA KUCHNIA</h3>


                        <p className="regular-text">
                            Kuchnia oraz zaplecze wyposażone są w dwie lodówki, zmywarkę, pralkę, piekarnik, 2 zlewy, czajnik, talerze, kubki, sztućce, garnki, patelnie oraz inne przedmioty umożliwiające swobodnie przygotowanie posiłków dla 14 osobowej grupy.
                        </p>
                    </div>
                </section>
                <section className="w-100 text align-items-center">
                    <div className="container gallery">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 mt-lg-5">

                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia7.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Waves at Sea"
                                    onClick={handleImageClick}
                                />
                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0 indent">
                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia8.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Waves at Sea"
                                    onClick={handleImageClick}
                                />
                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia5.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                    onClick={handleImageClick}
                                />
                            </div>

                            <div className="col-lg-4 mb-4 mb-lg-0 mt-lg-5">


                                <img
                                    src="./images/kuchnia/thumb/domek_salsa_wladyslawowo_kuchnia9.jpg"
                                    className="img-item w-100 shadow-1-strong rounded mb-4"
                                    alt="Waves at Sea"
                                    onClick={handleImageClick}
                                />

                            </div>
                        </div>
                    </div>


                </section>
                <section className="w-100 text align-items-center">
                    <Footer></Footer>
                </section>
            </div>

        </>
    );
}

export default Kitchen;