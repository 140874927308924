


function Card(props) {
    return (
        <div className={`${props.classs} card-primary card`}>
            <div className="card-img-top">
                {props.children}
            </div>

            <div className="card-body">
                <div className="card-description">{props.desc} <br /><a href={props.href}><strong><u>Zobacz!</u></strong></a></div>
            </div>
        </div>
    );
}

export default Card; 