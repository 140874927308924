import Footer from "../footer/Footer";

function About() {

    return (
        <>

            <div className="parallax">
                <section className="image" id="img3">
                    <section className="heading">
                        Poznaj nas bliżej
                    </section>
                </section>

                <section className="w-100 text flex-column align-items-center">
                    <div className="col-sm-12 col-lg-10 col-xxl-6" style={{ textAlign: 'left' }} >
                        <h3>KONTAKT</h3>


                        <p className="regular-text">
                            Domek Salsa<br />
                            ul. Floriana Ceynowy 8,<br />
                            84-120 Władyslawowo<br />
                            Tel: +48 784 909 239<br />
                            modzelewska21@gmail.com
                        </p>
                    </div>
                </section>
               
                <section className="w-100 text align-items-center">
                    <Footer></Footer>
                </section>
            </div>

        </>
    );
}

export default About;