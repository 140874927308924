import { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './NavMenu.min.css';

function NavMenu() {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();


    return (
        <Navbar id="navMenu" bg="light" expand="lg" expanded={expanded} style={{ minHeight: '78.8px' }}>
            <Container>
                <Navbar.Brand href="/"><img src="./logo_navbar.svg" alt="logo" /></Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="/" active={location.pathname === '/'}>Strona główna</Nav.Link>
                        <Nav.Link href="/Pokoje" active={location.pathname === '/Pokoje'}>Pokoje</Nav.Link>
                        <Nav.Link href="/Sala" active={location.pathname === '/Sala'}>Sala</Nav.Link>
                        <Nav.Link href="/Kuchnia" active={location.pathname === '/Kuchnia'}>Kuchnia</Nav.Link>
                        <Nav.Link href="/Cennik" active={location.pathname === '/Cennik'}>Ceny i pakiety</Nav.Link>
                        <Nav.Link href="/Galeria" active={location.pathname === '/Galeria'}>Galeria</Nav.Link>
                        <Nav.Link href="/Kontakt" active={location.pathname === '/Kontakt'}>Kontakt</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavMenu;