import { useRef, useEffect } from 'react';
import Card from "../card/Card";
import Footer from "../footer/Footer";
import './Index.min.css';


function Index() {

    const parallaxRef = useRef(null);

    useEffect(() => {
        const navbarHeight = document.getElementById("navMenu").style.height;
        const parallaxHeight = `calc(100vh - ${navbarHeight}px)`;
        parallaxRef.current.style.height = parallaxHeight;
    }, []);

    const handleImageClick = (event) => {
        const imageUrl = event.target.getAttribute("src");
        if (imageUrl) {
            window.open(imageUrl.replace('thumb/', ''), "_blank");
        }
    };
    return (
        <>

            <div ref={parallaxRef} className="parallax">
                <section className="image" id="img3">
                    <section className=" heading">
                        Domek salsa - idealne miejsce na relaks we Władysławowie
                    </section>
                </section>
                <section id="section1" className="text fs-xxl-3 fs-lg-5 d-flex justify-content-around flex-wrap" >
                    <div className="col-sm-12 col-lg-10 col-xxl-6" style={{ textAlign: 'left' }} >
                        <h3>DOMEK SALSA WNĘTRZA Z DUSZĄ</h3>
                        <p className="regular-text">
                        Naszym priorytetem jest udany wypoczynek Gości, dlatego stawiamy na serdeczny i rzetelny personel. Dokładamy wszelkich starań, aby nieustannie podnosić jakość usług oraz zapewnić maksimum komfortu.
                        </p>
                    </div>

                </section>
                <section className="image" id="imgzewn">
                </section>
                <section id="section1" className="text d-flex justify-content-around flex-wrap" >
                    <Card classs="card-green" href="Galeria" desc="Domek Salsa we Władysławowie to prywatny obiekt otulony przytulnym ogrodem położony na jednym z największych polskich kurortów. Naszych gości zachwyca cisza i prywatność jaką zapewniamy podczas pobytu w Domku Salsa. Balkony i tarasy dają możliwość podziwiania zatoki Puckiej.">
                        <video src="./images/pokazowy.mp4" className="img-item w-100 shadow-1-strong rounded" autoPlay="autoplay" muted="muted" loop="loop" type="video/mp4" playsInline />
                    </Card>
                    <Card classs="card-green" href="Pokoje" desc={<div>Minimalizm, subtelna elegancja, ręcznie wykonane dekoracje znajdujące się w 6 pokojach Domku Salsa podkreślają piękno otoczenia. Wnętrza zaskakują przytulnością, a ich aranżacja sprzyja atmosferze wypoczynku. <br /> Domek Salsa posiada:<br />• 4 pokoje 2 osobowe<br />• 2 pokoje 3 osobowe<br />• 3 łazienki.<br />Obiekt przeszedł kapitalny remont w 2023r.</div>} >
                        <img
                            src="./images/pokoj/thumb/domek_salsa_wladyslawowo_pokoj15.jpg"
                            className="img-item w-100 shadow-1-strong rounded"
                            alt="Mountains in the Clouds"
                            onClick={handleImageClick}
                        />
                    </Card>
                    <Card classs="card-green" href="Sala" desc="Doskonałym atutem Domku Salsa jest wspólna kuchnia oraz przestronna sala dostosowana dla całej 14 osobnej grupy.">
                        <img
                            src="./images/sala/thumb/domek_salsa_wladyslawowo_sala5.jpg"
                            className="img-item w-100 shadow-1-strong rounded"
                            alt="Mountains in the Clouds"
                        />
                    </Card>

                </section>

                <section className="text d-flex justify-content-around fs-4 flex-wrap" >
                    <div className="col-sm-12 col-lg-10 col-xxl-6" style={{ textAlign: 'left' }} >
                        <p className="regular-text">
                        Domek Salsa to doskonałe miejsce do odpoczynku. Oferujemy Naszym gościom cały obiekt otulony ogrodem, posiadający bazę noclegową z dostępną ofertą dla 14 osób, prywatną salę oraz kuchnię.<br />
                        </p>
                        <br />
                        <h3>LOKALIZACJA</h3>
                        <br />
                        <img
                            src="./images/lokalizacja.png"
                            className="img-item w-100 shadow-1-strong rounded"
                            alt="Mountains in the Clouds"
                            onClick={() => window.open('https://www.google.com/maps/dir//domek+salsa/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x46fdb3963713925b:0xa2d85c9d350e32a8?sa=X&ved=2ahUKEwiC3Pi87teAAxVzKBAIHenTCwsQ9Rd6BAg_EAA&ved=2ahUKEwiC3Pi87teAAxVzKBAIHenTCwsQ9Rd6BAhFEAU', '_blank')}
                        />

                    </div>
                </section>
                <section className="w-100 text align-items-center">
                    <Footer></Footer>
                </section>

            </div>
        </>
    );
}

export default Index;