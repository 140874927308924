import { Outlet, Link } from "react-router-dom";
import NavMenu from "../navmenu/NavMenu";

const Layout = () => {
    return (
        <>
            <div className="page">

                <main>
                    <NavMenu />

                    <div className="w-100 p-0 m-0">
                        <Outlet />
                    </div>
                </main>


            </div>


        </>
    )
};

export default Layout;